@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap);
/*Fonts*/

/*Keyframe Animation*/
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}

/*Global Settings*/

html {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 800% 800%;
  animation: gradient 15s ease infinite;
}

html,
body,
#root,
main {
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100% !important;
}

p,
h1,
h2,
h3,
a {
  font-family: 'Lato', sans-serif;
}

/*Primary Elements*/
#home,
#projects {
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100% !important;
}

#home {
  display: grid;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

.project {
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}

/*Toggle Classes*/
.display {
  display: block;
  animation: appear 0.5s;
}

.noDisplay {
  display: none;
  animation: disappear 0.5s;
}

/* Toggle */
.hamburger:hover {
  cursor: pointer;
}

/* Nav Menu Elements */
.navHolder {
  padding: 20px;
}

.navBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 6;
}

.navMenuContainer {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-around;
}
.navList ul {
  list-style: none;
  padding-left: 0;
}
.navButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: small;
  font-weight: 300;
  text-decoration: none;
}

a.navButton p {
  margin: 5px 0 10px 0;
}

/* Homepage */

#home img.homeLogo {
  width: 300px;
}
@media (max-width: 374px) {
  #home img.homeLogo {
    width: 90vw;
  }
}

/* Scroll Panel */

.scrolling-panel {
  height: 100vh; /* Adjust as needed */
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.panel-item {
  transition: opacity 0.5s ease-in-out;
  margin-bottom: 20px;
  width: 80%; /* Adjust as needed */
}

.panel-item:not(.visible) {
  opacity: 0;
  pointer-events: none;
}


/* Page Title Holder */
.pageTitle {
  position: absolute;
  z-index: 1;
  width: 100%;
  text-align: center;
}

.pageTitle h1,
.pageTitle h3 {
  font-weight: 300;
}
@media (max-width: 400px) {
  .pageTitle h1, .pageTitle h3  {
    font-weight: 300;
  }
}

/*Project & Tile Holder*/

.projects h1 {
  font-weight: 100;
}

.projectHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: normal;
  grid-gap: 20px;
  padding: 0 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.tileHolder {
  width: 23.4%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.tileHolder a {
  color: #ffffff;
  text-decoration: none;
}
.tileImage img {
  width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
}
.tileTitleContainer {
  border-radius: 0 0 20px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 0;
  margin-top: -4px;
}
.tileTitleContainer h2 {
  margin: 0;
  text-align: center;
  font-size: 1.5vw;
  font-weight: 100;
}

@media (max-width: 976px) {
  .tileHolder {
    width: 31.8%;
  }
  .tileTitleContainer h2 {
    font-size: 2.5vw;
  }
}
@media (max-width: 925px) {
  .tileHolder {
    width: 48.8%;
  }
  .tileTitleContainer h2 {
    font-size: 3vw;
  }
}
@media (max-width: 889px) {
  .tileHolder {
    width: 48.5%;
  }
}
@media (max-width: 722px) {
  .tileHolder {
    width: 47.5%;
  }
}
@media (max-width: 439px) {
  .tileHolder {
    width: 47%;
  }
}
@media (max-width: 374px) {
  .tileHolder {
    width: 100%;
  }
  .tileTitleContainer h2 {
    font-size: 6vw;
  }
}

/*Project Info & Toggle Class*/

.infoLogo:hover {
  cursor: pointer;
}

.infoLogoContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;
  z-index: 6;
}

.noDisplayInfo {
  border: 2px solid #000000;
  padding: 25px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9);
  width: 90vw;
  max-height: 40vh;
  position: fixed;
  left: 4vw;
  bottom: -200px;
  animation: disappear 1s;
  display: none;
}

.displayInfo {
  border: 2px solid #000000;
  padding: 25px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9);
  width: 90vw;
  max-height: 40vh;
  position: fixed;
  left: 4vw;
  bottom: 40px;
  animation: appear 1s;
  display: block;
  font-weight: 300;
}

.topSection {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.topSection h2 {
  margin: 0 0 10px 0;
  font-weight: 300;
}

.repoLink {
  position: absolute;
  right: 30px;
  display: flex;
  font-size: 15px;
  top: 32px;
  color: #000000;
  text-decoration: none;
  font-weight: 400;
}

@media (max-width: 420px) {
  .topSection {
    padding: 5px 0 15px 0;
}
  .repoLink {
    right: unset;
    top: 62px;
  }
}

.descriptionSection {
  overflow: scroll;
  height: 150px;
}

.whiteFont {
  color: #ffffff;
}

.blackBackground {
  background-color: #1e1e1e;
}

.code {
  font-family: 'Courier Prime';
  font-size: smaller;
  font-weight: 300;
  padding: 3px 3px 0 3px;
}

.yellowFont {
  color: #e7e7b6;
}

.lightBlueFont {
  color: #9cdcfe;
}

.darkBlueFont {
  color: #4fc1ff;
}

.lightGreenFont {
  color: #baea3f;
}

@media (max-width: 722px) {
  .displayInfo {
    margin: 10px;
    left: 0vw;
  }
}

/*Leva Control Panel*/

.leva-c-kWgxhW-bCBHqk-fill-false {
  z-index: 2 !important;
}
@media (max-width: 889px) {
  .leva-c-kWgxhW-bCBHqk-fill-false {
    top: 70px !important;
  }
}
@media (max-width: 439px) {
  .leva-c-kWgxhW-bCBHqk-fill-false {
    left: 60px !important;
  }
}

